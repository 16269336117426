import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["GOERLI_ARBI"] = 137] = "GOERLI_ARBI";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0x0C7bE96f6721F119a95FF3E68bd7fC4b25d0881D";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0x0C7bE96f6721F119a95FF3E68bd7fC4b25d0881D";
export var FACTORY_ADDRESS_GOERLI_ARBI = "0x0C7bE96f6721F119a95FF3E68bd7fC4b25d0881D";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.GOERLI, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x0C7bE96f6721F119a95FF3E68bd7fC4b25d0881D"), _define_property(_obj, ChainId.GOERLI_ARBI, FACTORY_ADDRESS_GOERLI_ARBI), _obj);
export var INIT_CODE_HASH = "0x9470e79620a9c3e6a94149ae56f24e89d9d491030329ae1a73922624569cbac5";
var INIT_CODE_HASH_ETH = "0x9470e79620a9c3e6a94149ae56f24e89d9d491030329ae1a73922624569cbac5";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.GOERLI, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BSC_TESTNET, "0x9470e79620a9c3e6a94149ae56f24e89d9d491030329ae1a73922624569cbac5"), _define_property(_obj1, ChainId.GOERLI_ARBI, "0x9470e79620a9c3e6a94149ae56f24e89d9d491030329ae1a73922624569cbac5"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI, new Token(ChainId.GOERLI, "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.GOERLI_ARBI, new Token(ChainId.GOERLI_ARBI, "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270", 18, "WMATIC", "Wrapped MATIC", "https://polygon.technology")), _obj3);
var _obj4;
export var WBNB = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _obj4);
var _obj5;
export var WNATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj5, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj5, ChainId.GOERLI, WETH9[ChainId.GOERLI]), _define_property(_obj5, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj5, ChainId.BSC_TESTNET, WBNB[ChainId.BSC_TESTNET]), _define_property(_obj5, ChainId.GOERLI_ARBI, WETH9[ChainId.GOERLI_ARBI]), _obj5);
var _obj6;
export var NATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj6, ChainId.GOERLI, {
    name: "Goerli Ether",
    symbol: "GOR",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _define_property(_obj6, ChainId.GOERLI_ARBI, {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18
}), _obj6);
